<template>
  <div class="intro">
    <transition name="slide-fade" appear>
      <logo-presenter v-bind="ak" class="left"></logo-presenter>
    </transition>
    <div class="line"></div>
    <transition name="slide-fade" appear>
      <logo-presenter v-bind="mr" class="right"></logo-presenter>
    </transition>
  </div>
</template>

<script>
// @ is an alias to /src
import LogoPresenter from '@/components/LogoPresenter.vue'

export default {
  name: 'Intro',
  components: {
    LogoPresenter
  },
  data: () => ({
    ak: {
      name: 'Angelika Kamlage',
      logo: require('@/assets/aklogo.png'),
      logoCentered: true,
      link: 'https://angelika-kamlage.de'
    },
    mr: {
      name: 'Malte Reddig',
      logo: require('@/assets/mrlogo.png'),
      link: 'https://rddg.org'
    },
    show: false
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
$mobile-breakpoint: 420px;
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .9s ease;
}

.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  @media screen and (min-width: $mobile-breakpoint) {
    &.left {
      transform: translateX(-30px);
    }

    &.right {
      transform: translateX(30px);
    }
  }
  @media screen and (max-width: $mobile-breakpoint) {
    &.left {
      transform: translateY(-30px);
    }

    &.right {
      transform: translateY(30px);
    }
  }

  opacity: 0;
}

.hidden {
  opacity: 0;
}
</style>

<style lang="scss">
$line-width: 2px;
$mobile-breakpoint: 420px;

.line {
  border-left: 0px;
  border-top: 0px;
  border-right: 0px;
  border-bottom: $line-width solid;
  margin-top: 10%;
  margin-bottom: 15px;
}

.intro {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .left, .right {
    width: 100%;
  }

  .left {
    img {
      width: 27.5vh;
      max-width: 75%;
    }
  }
}

@media screen and (min-width: $mobile-breakpoint) {
  .line {
    border-right: $line-width solid;
    border-bottom: 0px;
    margin-top: 0;
    margin-bottom: 0px;
  }

  .intro {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    height: 30vh;
    min-height: 400px;

    .left {
      // width: 68%;
      margin-right: 5%;
      text-align: center;

      img {
        width: auto;
        max-width: auto;
      }
    }

    .right {
      // width: 38%;
      margin-left: 10%;
      text-align: left;
    }
  }
}
</style>
