import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'UA-42278802-4',
  debug: {
    sendHitTask: process.env.NODE_ENV === 'production'
  },
  router
})

Vue.config.productionTip = false

document.title = 'Familie Kamlage Reddig'

new Vue({
  router,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
