<template>
  <div class="family">
    <h1>{{ name }}</h1>
    <p>
      <span>Angelika Kamlage, Gero Reddig,</span> &nbsp;
      <span>Malte Reddig, Reinhard Reddig</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'Family',
  data: () => ({
    name: 'Familie Kamlage Reddig'
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.family {
  font-family: 'Fira Sans', sans-serif;
  opacity: 0.5;
  text-align: center;

  h1 {
    font-weight: 500;
    font-size: 1.2em;
  }

  p {
    line-height: 2;

    > span {
      display: inline-block;
    }
  }
}
</style>
