<template>
  <div class="index">
    <div class="content">
      <intro class="intro"></intro>
      <family class="family"></family>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Intro from '@/components/Intro.vue'
import Family from '@/components/Family.vue'

export default {
  name: 'Index',
  components: {
    Intro,
    Family
  }
}
</script>

<style lang="scss" scoped>
.index {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-top: 5vh;
  padding-bottom: 5vh;

  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.content {
  width: 100%;
  margin-bottom: 20%;

  > * {
    margin-top: 4vh;

    &:first {
      margin-top: 0;
    }
  }
}

.intro {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  font-size: 0.7em;
}

$mobile-breakpoint: 420px;

@media screen and (min-width: $mobile-breakpoint) {
  .content {
    min-width: 380px;
    width: 80%;
    max-width: 1300px;
    margin: auto;
  }

  .family {
    width: 77%;
    margin-left: auto;
    margin-right: auto;
  }

  .intro {
    width: 100%;
    font-size: 1em;
  }
}
</style>
