<template>
  <div class="logo-presenter" :class="{ 'pointer': hasLink }" v-on:click="click">
    <div class="image-wrapper" :class="{ 'logo-centered': logoCentered }">
      <img :src="logo">
    </div>
    <h3><a :href="link" v-on:click.prevent="click">{{ name }}</a></h3>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  props: {
    name: String,
    logo: String,
    logoCentered: Boolean,
    link: String
  },
  computed: {
    hasLink () { return !!this.link }
  },
  methods: {
    click () {
      if (this.hasLink) {
        this.$ga.event('Link', 'click', `name: ${this.name}, url: ${this.link}`, 200)
        // this.$ga.page(this.link)
        window.location.href = this.link
      } else {
        this.$ga.event('Link', 'click', `name: ${this.name}, url: false`, 404)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 0;
  padding: 0;
  font-family: 'Libre Baskerville', serif;
  font-weight: 400;
  font-size: 1.7em;
}

.pointer {
  cursor: pointer;
}

.logo-presenter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;

  h3 {
    margin-top: 8%;
    margin-bottom: 2%;
    flex: 0 0 1;
    a {
      text-decoration: none;
      color: #2c3e50;
    }
  }

  .image-wrapper {
    flex: 1;
  }

  .logo-centered {
    justify-content: center;
  }
}

.image-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0;
  width: 100%;
  margin-top: 10%;
  margin-bottom: 15px;
  max-height: 65%;

  > img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
